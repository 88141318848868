import React, { useState, useEffect } from 'react'
import Joyride, { STATUS } from 'react-joyride'
import { manageProfileTourGuideService } from '../../services/tourGuideService/manageProfileTourGuideService'
import { tourGuideAction } from '../../shared/tourGuideAction'
import { userDetailService } from '../../services/userDetailService/userDetailService'

const ManageProfileTourGuide = ({ run }) => {
  const [autoRun, setAutoRun] = useState(false)
  const [steps] = useState([
    {
      target: '.customer-id',
      title: 'Account ID and Role',
      content:
        'Account ID is system generated number which is your unique ID in the Smart! system. Following the Account ID the user role is displayed.',
      disableBeacon: true,
      spotlightPadding: 0,
    },
    {
      target: '.personal-information-section',
      title: 'Personal Information',
      content:
        'Please note that every field in Personal Information is mandatory (marked with a red asterisk). Therefore, please complete the information by filling out the empty fields.',
      disableBeacon: true,
      styles: {
        spotlight: {
          borderRadius: '10px',
        },
      },
    },
    {
      target: '.username-tour',
      title: 'Personal Information',
      content:
        'Your initial Username is the same as your Email Address. You can change it to your liking.',
      disableBeacon: true,
      spotlightPadding: 0,
    },
    {
      target: '.company-information-section',
      title: 'Company Information',
      content: (
        <p>
          Please note that every field in Company Information is mandatory
          (marked with a red asterisk <span className="tour-red">*</span>).
          Therefore, please complete the information by filling out the empty
          fields.
        </p>
      ),
      disableBeacon: true,
      spotlightPadding: 0,
      styles: {
        spotlight: {
          borderRadius: '10px',
        },
      },
    },
    {
      target: '.contact-information-section',
      title: 'Contact Information',
      content: (
        <p>
          Please note that every mandatory field must be filled (marked with a
          red asterisk <span className="tour-red">*</span>). Therefore, please
          complete the information by filling out the empty fields.
        </p>
      ),
      disableBeacon: true,
      spotlightPadding: 0,
      styles: {
        spotlight: {
          borderRadius: '10px',
        },
      },
    },
    {
      target: '.timezone-tour',
      title: 'Contact Information - TimeZone',
      content:
        'The Time Zone setting is essential in relevance to the Smart! business hours and the order’s working hours. Please make sure your Time Zone is correct according to your location.',
      disableBeacon: true,
      spotlightPadding: 0,
    },
    {
      target: '.btn-save-profile',
      title: 'Save Changes button',
      content:
        'Don’t forget to click on the Save Changes button after any information is updated in your Profile.',
      disableBeacon: true,
      lastStep: true,
      spotlightPadding: 0,
    },
  ])

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    buttonClose: {
      display: 'none',
    },
  }

  const handleJoyrideCallback = (data) => {
    const { status, lifecycle } = data

    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) &&
      lifecycle === 'complete'
    ) {
      tourGuideAction('manageProfile', 'false', () => {
        userDetailService.emitReload(true)
      })
    }
  }

  useEffect(() => {
    const subscription = manageProfileTourGuideService
      .reload()
      .subscribe((value) => {
        if (value) {
          setAutoRun(run)
        }
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [run])

  return (
    <div className="manage-profile-form">
      <Joyride
        callback={handleJoyrideCallback}
        run={autoRun}
        steps={steps}
        styles={customStyles}
        showSkipButton={true}
        floaterProps={{ disableAnimation: true }}
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Finish',
          next: 'Next',
          skip: 'Skip',
        }}
        continuous={true}
      />
    </div>
  )
}

export default ManageProfileTourGuide
