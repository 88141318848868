import React from 'react'
import PropTypes from 'prop-types'

import './AppLoader.scss'

// import AppApiLoader from '../AppApiLoader/AppApiLoader'
import Spinner from '../Spinner/Spinner'
import clientLogin from '../../../shared/clientLogin'
import ImgSpinner from '../ImgSpinner/ImgSpinner'

function AppLoader({ branding = null, loading = false }) {
  const clientId = localStorage.getItem('clientId')
  return (branding && branding.NAME === 'Smart') ||
    (clientId && clientId === clientLogin.ADMIN) ? (
    <ImgSpinner isLoading={loading} className="app-loader-spinner" />
  ) : (
    // <AppApiLoader isLoading={loading} className="client-app-loader" />
    loading && (
      <div className="client-app-loader">
        <Spinner />
      </div>
    )
  )
}

AppLoader.propType = {
  branding: PropTypes.object,
  loading: PropTypes.bool,
}

export default AppLoader
