import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index'
import { ValidateImpersonateTokenAPI } from '../../api/User/ValidateImpersonateToken'
import toastCenter from '../../shared/toastCenter'
import { useQuery } from '../../shared/utility'

function ImpersonateCustomer({ apiStart, apiStop }) {
  let query = useQuery()
  let token = query.get('token')

  const [tokenData, setTokenData] = useState(null)

  useEffect(() => {
    if (tokenData) {
      localStorage.setItem('clientId', tokenData.client.id)
      localStorage.setItem('token', tokenData.accessToken)
      localStorage.setItem('expirationDate', tokenData.accessTokenExpiresAt)
      localStorage.setItem('userId', tokenData.user.id)
      if (tokenData.user.isClientWhitelabel) {
        localStorage.setItem('view', 'client')
      }
      window.location.href = '/'
    }
  }, [tokenData])

  useEffect(() => {
    if (token) {
      apiStart()
      const validateImpersonateTokenAPI = new ValidateImpersonateTokenAPI()
      const onNext = (response) => {
        apiStop()
        if (response.success && response.data) {
          setTokenData(response.data)
        }
      }
      const onError = () => {
        apiStop()
        toastCenter.messageServerError()
      }
      const onComplete = () => {}
      validateImpersonateTokenAPI.subscribe(token, onNext, onComplete, onError)

      return () => {
        validateImpersonateTokenAPI.unsubscribe()
      }
    }
  }, [token, apiStart, apiStop])

  return <div>{!tokenData && <p>Token is not valid</p>}</div>
}

const mapDispatchToProps = (dispatch) => {
  return {
    apiStart: () => dispatch(actions.apiStart()),
    apiStop: () => dispatch(actions.apiStop()),
  }
}

export default connect(null, mapDispatchToProps)(ImpersonateCustomer)
