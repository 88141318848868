import React from 'react'

import './ImgSpinner.scss'

function ImgSpinner({ isLoading = false, className = '' }) {
  return (
    <div
      className={`spinner-wrapper ${className} ${
        isLoading ? 'show' : 'd-none'
      }`}
    >
      <img src="/favicon-32x32.png" alt="Logo Spinner" className="loader"></img>
    </div>
  )
}

export default ImgSpinner
