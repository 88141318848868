import React from 'react'

import './Spinner.scss'

export default function Spinner() {
  return (
    <div className="spinner-wrapp">
      <img src="/favicon-32x32.png" alt="Logo Spinner" className="loader"></img>
    </div>
  )
}
