import React, { useState, useEffect, useRef } from 'react'
import { Transition } from 'react-transition-group'
import feather from 'feather-icons'
import * as Icon from 'react-feather'
import { withRouter } from 'react-router-dom'

import './PaygCart.scss'
import * as Router from '../../../shared/router'
import { createMarkup } from '../../../shared/utility'
import SkeletonLine from '../../Skeleton/SkeletonLine'
import { GetCartAPI } from '../../../api/Cart/GetCart'
// import { CheckoutCartAPI } from '../../../api/Cart/CheckoutCart'
// import toastCenter from '../../../shared/toastCenter'

import { connect } from 'react-redux'
import * as actions from '../../../store/actions/index'

const duration = 300

const defaultUserMenuStyle = {
  transition: `all ${duration}ms ease-in-out`,
  visibility: 'visible',
  opacity: 0,
}

const transitionUserMenuStyles = {
  entered: { opacity: 1, visibility: 'visible' },
  exited: { opacity: 0, visibility: 'hidden' },
}

// const dummyData = [
//   {
//     name: 'Item 1',
//     qty: 1,
//     price: 10,
//   },
//   {
//     name: 'Item 2',
//     qty: 3,
//     price: 15,
//   },
// ]

const totalSkeleton = 3

const skeletons = []

for (let x = 0; x < totalSkeleton; x++) {
  skeletons.push(
    <div className="d-flex flex-row mb-3" key={x}>
      <div className="d-flex flex-column mr-3">
        <SkeletonLine style={{ width: '45px' }} />
      </div>
      <div className="d-flex flex-column flex-grow-1">
        <div className="d-flex mb-2">
          <SkeletonLine style={{ height: '25px' }} />
        </div>
        <div className="d-flex">
          <SkeletonLine />
        </div>
      </div>
    </div>
  )
}

const Cart = ({ user, history, paygCart, removeFromCart, resetCart }) => {
  const [isPopoverVisible, setPopoverVisible] = useState(false)
  const [cart, setCart] = useState([])
  const [subTotal, setSubTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const wrapperRef = useRef(null)

  useEffect(() => {
    if (paygCart.items) {
      feather.replace()
      setIsLoading(true)

      const listCartAPI = new GetCartAPI()

      const onNext = (response) => {
        if (response.success) {
          setCart(response.data)

          setIsLoading(false)
        } else {
          setIsLoading(false)
        }
      }

      const onComplete = () => {}

      const onError = () => {
        setIsLoading(false)
      }

      listCartAPI.subscribe(paygCart.items, onNext, onComplete, onError)

      return () => {
        listCartAPI.unsubscribe()
      }
    }

    return () => {
      setCart([])
    }
  }, [paygCart])

  let activeClass = isPopoverVisible ? 'active' : ''

  const handlePopoverMenu = () => {
    setPopoverVisible(!isPopoverVisible)
  }

  useEffect(() => {
    if (cart && cart.length > 0) {
      const initialValue = 0
      const countTotal = cart.reduce(
        (acc, obj) => parseInt(acc) + parseInt(obj.price) * parseInt(obj.qty),
        initialValue
      )

      setSubTotal(countTotal)
    }
  }, [cart])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  const handleClickOutside = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setPopoverVisible(false)
    }
  }

  const handleViewCart = () => {
    history.push(Router.cartCheckout)
    setPopoverVisible(false)
  }

  // const handleViewCheckout = () => {
  //   const checkoutCartAPI = new CheckoutCartAPI()

  //   const onNext = (response) => {
  //     if (response.success) {
  //       resetCart()
  //       setPopoverVisible(false)
  //       history.push(`/checkout/process/invoice/${response.data._id}`)
  //     } else {
  //       toastCenter.messageServerErrorCustom(response)
  //     }
  //   }

  //   const onComplete = () => {}

  //   const onError = () => {}

  //   checkoutCartAPI.subscribe(cart, onNext, onComplete, onError)

  //   return () => {
  //     checkoutCartAPI.unsubscribe()
  //   }
  // }

  return (
    <div className="top-link-user" ref={wrapperRef}>
      <button
        type="button"
        className={`btn btn-icon btn-top-nav ${activeClass}`}
        onClick={handlePopoverMenu}
      >
        <i data-feather="shopping-bag"></i>
        {!isLoading && cart && cart.length > 0 && (
          <span className="badge badge-danger badge-top-link">
            {cart.length}
          </span>
        )}
      </button>
      <Transition in={isPopoverVisible} timeout={0} mountOnEnter unmountOnExit>
        {(state) => (
          <div
            className="popover-notification-toolbar popover-notification-toolbar-cart"
            style={{
              ...defaultUserMenuStyle,
              ...transitionUserMenuStyles[state],
            }}
          >
            <div className="d-flex flex-column p-4">
              <div className="d-flex">
                <span className="font-weight-bold text-16">Cart</span>
              </div>

              <hr className="ml-0 mr-0" />

              {!isLoading && cart !== null && cart.length === 0 && (
                <div
                  className="d-flex flex-column justify-content-center align-items-center"
                  style={{ height: '255px' }}
                >
                  <div className="d-block text-center">
                    <Icon.ShoppingBag className="text-gray feather-50" />
                  </div>
                  <div className="d-block mt-4 text-center">
                    <span className="text-gray">No product in the cart</span>
                  </div>
                </div>
              )}

              {!isLoading && cart !== null && cart.length > 0 && (
                <div className="d-flex cart-items-wrapper">
                  {isLoading && (
                    <div className="d-flex flex-column w-100">{skeletons}</div>
                  )}

                  {!isLoading && cart && cart.length > 0 && (
                    <ul className="list-unstyled m-0 cart-items w-100">
                      {cart.map((item, idx) => (
                        <li className="p-3" key={idx}>
                          <div className="d-flex flex-row position-relative">
                            {/* <div className="d-flex">
                              <div className="img-prev-cart-item" style={{ backgroundImage: `url(${item.image})` }}></div>
                            </div> */}
                            <div className="d-flex flex-grow-1 flex-column ml-3">
                              <div>
                                <div
                                  className="font-weight-bold"
                                  dangerouslySetInnerHTML={createMarkup(
                                    item.name
                                  )}
                                ></div>
                              </div>
                              <div className="d-flex align-items-center">
                                <span className="mr-2">{item.qty}</span>
                                <span className="mr-2">x</span>
                                <span className="text-14 font-weight-bold text-primary">
                                  {user.currencySimbol} {item.price}
                                </span>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="btn btn-icon btn-icon-danger"
                              onClick={() => removeFromCart(item)}
                            >
                              <Icon.X className="feather" />
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}

              {!isLoading && cart !== null && cart.length > 0 && (
                <hr className="ml-0 mr-0" />
              )}

              {isLoading && (
                <div className="d-flex flex-row w-100">
                  <div className="d-flex flex-grow-1">
                    <SkeletonLine />
                  </div>
                  <div className="ml-3"></div>
                  <div className="d-flex flex-grow-1">
                    <SkeletonLine />
                  </div>
                </div>
              )}

              {!isLoading && cart !== null && cart.length > 0 && (
                <>
                  <div className="d-flex pl-3 pr-3 pb-3">
                    <div className="d-flex flex-grow-1">
                      <span className="font-weight-bold text-16">
                        Sub Total
                      </span>
                    </div>
                    <div className="d-flex flex-grow-1 justify-content-end">
                      <span className="font-weight-bold text-16 text-primary">
                        {user.currencySimbol} {subTotal}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex">
                    <button
                      className="btn btn-darkprimary d-flex flex-grow-1 justify-content-center"
                      onClick={handleViewCart}
                    >
                      View Cart
                    </button>
                    <div style={{ width: '10px' }}></div>
                    {/* <button
                      className="btn btn-darkprimary d-flex flex-grow-1 justify-content-center"
                      onClick={handleViewCheckout}
                    >
                      Checkout
                    </button> */}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </Transition>
    </div>
  )
}

const mapStateToProps = ({ user, paygCart }) => {
  return { user, paygCart }
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeFromCart: (item) => dispatch(actions.removeFromCart(item.id)),
    resetCart: () => dispatch(actions.resetCart()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart))
