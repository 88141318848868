import React, { useState, useEffect } from 'react'
import Joyride, { STATUS } from 'react-joyride'
import { manageInvoiceTourGuideService } from '../../services/tourGuideService/manageInvoiceTourGuideService'
import { tourGuideAction } from '../../shared/tourGuideAction'
import { userDetailService } from '../../services/userDetailService/userDetailService'
import { dummyInvoiceTourGuideService } from '../../services/tourGuideService/dummyTourGuideService'

const ManageInvoiceTourGuide = ({ run }) => {
  const [autoRun, setAutoRun] = useState(false)
  const [steps] = useState([
    {
      target: '.manage-invoice',
      title: 'Manage Invoices & Receipts',
      content:
        'Click on the Manage Invoices & Receipts button to show the Manage Invoices & Receipts screen',
      disableBeacon: true,
      type: 'hover',
      isFixed: true,
      spotlightPadding: 0,
      styles: {
        spotlight: {
          border: '1px solid #F16C4A',
        },
      },
    },
    {
      target: '.table-listing-wrapper',
      title: 'Manage Invoice & Receipts List',
      content:
        'This page will show you a list of information regarding your invoices & receipts. You can view the details of your invoices or receipts, pay your unpaid invoices and download your invoices or receipts.',
      disableBeacon: true,
      type: 'hover',
      isFixed: true,
      spotlightPadding: 0,
      styles: {
        spotlight: {
          borderRadius: '10px',
        },
      },
    },
    {
      target: '.table-pagination',
      title: 'Invoice & Receipts Page',
      content:
        'If you have many Invoices or Receipts, they will be listed on multiple pages, move between pages by clicking the page number or clicking the next, previous, last-page, or first-page button. You can also change the number of displayed rows per page.',
      disableBeacon: true,
      type: 'hover',
      isFixed: true,
      spotlightPadding: 0,
      lastStep: true,
    },
  ])

  const customStyles = {
    overlay: {
      position: 'fixed',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    buttonClose: {
      display: 'none',
    },
  }

  const handleJoyrideCallback = (data) => {
    const { status, lifecycle } = data

    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) &&
      lifecycle === 'complete'
    ) {
      tourGuideAction('manageInvoice', 'false', () => {
        userDetailService.emitReload(true)
        dummyInvoiceTourGuideService.emitReload(false)
      })
    }
  }

  useEffect(() => {
    const subscription = manageInvoiceTourGuideService
      .reload()
      .subscribe((value) => {
        if (value) {
          setAutoRun(run)

          if (run) {
            dummyInvoiceTourGuideService.emitReload(true)
          }
        }
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [run])

  return (
    <div className="tour-guide-manage-invoice">
      <Joyride
        callback={handleJoyrideCallback}
        run={autoRun}
        steps={steps}
        styles={customStyles}
        floaterProps={{ disableAnimation: true }}
        showSkipButton={true}
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Finish',
          next: 'Next',
          skip: 'Skip',
        }}
        continuous={true}
      />
    </div>
  )
}

export default ManageInvoiceTourGuide
