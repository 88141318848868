import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import validator from 'validator'
import update from 'immutability-helper'
import { withRouter } from 'react-router-dom'

import './ResetPassword.scss'
import * as Router from '../../../shared/router'
import PasswordField from '../../../components/Form/PasswordField/PasswordField'
import branding from '../../../shared/branding'
import { ChangePasswordAPI } from '../../../api/User/ChangePassword'
import FormHandler from '../../../factory/FormHandler'
import toastCenter, { toastMessageType } from '../../../shared/toastCenter'
import LogoLogin from '../Logo.png'
import ImgSpinner from '../../../components/Loader/ImgSpinner/ImgSpinner'
import { useQuery } from '../../../shared/utility'
import SvgCheckSuccess from '../../../Icons/CheckSuccess'

const ResetPassword = ({ history }) => {
  let query = useQuery()
  let token = query.get('token')
  let userType = query.get('type')
  let email = query.get('email')

  const defaultValueInput = {
    value: '',
    isEmpty: false,
    isInvalid: false,
  }

  const [password, setPassword] = useState(defaultValueInput)
  const [retypePassword, setRetypePassword] = useState(defaultValueInput)
  const [isSendedResetPassword, setIsSendedResetPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)

  useEffect(() => {
    if (token && userType && email) {
      setPageLoading(false)
    } else {
      history.push(Router.signin)
    }
  }, [history, token, userType, email])

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  const handleSubmit = () => {
    if (
      password.isEmpty ||
      retypePassword.isEmpty ||
      password.isInvalid ||
      retypePassword.isInvalid ||
      validator.isEmpty(password.value) ||
      validator.isEmpty(retypePassword.value)
    ) {
      return
    } else {
      const data = new FormHandler()
      data.append('token', token)
      data.append('type', userType)
      data.append('email', email)
      data.append('newPassword', password.value)
      data.append('reNewPassword', retypePassword.value)

      setIsLoading(true)

      const onNext = (response) => {
        if (response.success) {
          setIsSendedResetPassword(true)
        } else {
          let message = response.messages
            ? response.messages
            : response.message
            ? response.message
            : 'Someting went wrong'
          toastCenter.message('Failed', message, toastMessageType.ERROR, false)
        }
      }
      const onComplete = () => {
        setIsLoading(false)
      }
      const onError = () => {
        toastCenter.messageServerError()
      }

      const changePasswordAPI = new ChangePasswordAPI()
      changePasswordAPI.subscribe(data.all(), onNext, onComplete, onError)
    }
  }

  const handleChangePassword = (e) => {
    setPassword(
      update(password, {
        value: { $set: e.target.value },
        isEmpty: { $set: validator.isEmpty(e.target.value) },
        isInvalid: {
          $set:
            !validator.isEmpty(e.target.value) &&
            !validator.isLength(e.target.value, { min: 6, max: 20 }),
        },
      })
    )
  }

  const handleChangeRetypePassword = (e) => {
    setRetypePassword(
      update(retypePassword, {
        value: { $set: e.target.value },
        isEmpty: { $set: validator.isEmpty(e.target.value) },
        isInvalid: {
          $set:
            !validator.isEmpty(e.target.value) &&
            (!validator.isLength(e.target.value, { min: 6, max: 20 }) ||
              password.value !== e.target.value),
        },
      })
    )
  }

  return (
    <div className="reset-password-wrapper h-100 overflow-hidden">
      <div className="ornament-top-1"></div>
      <div className="ornament-top-2"></div>
      <Helmet>
        <title>{branding.NAME} - Reset Password</title>
      </Helmet>

      {pageLoading ? (
        <ImgSpinner isLoading={true} />
      ) : (
        <>
          {isLoading && <ImgSpinner isLoading={true} />}

          <div className="container position-relative h-100 zindex-1">
            <div className="logo">
              {branding.NAME === 'Smart' && (
                <a href={branding.DOMAIN}>
                  <img src={LogoLogin} width="150" alt="" />
                </a>
              )}
            </div>

            <div
              className={`form-reset-password fade ${
                isSendedResetPassword ? 'hide invisible' : 'show visible'
              }`}
            >
              <h1>Forgot Password</h1>
              <p>Enter a new password for your account.</p>

              <div className="form-field-wrapp">
                <PasswordField
                  label="Password"
                  formClassName="passsword-form-group"
                  placeholder="Enter Password…"
                  id="password"
                  onKeyDown={handleKeyDown}
                  isRequired
                  maxLength={20}
                  autoComplete="password"
                  isInvalid={password.isInvalid}
                  isEmpty={password.isEmpty}
                  onChange={handleChangePassword}
                  value={password.value}
                />
                <PasswordField
                  label="Retype Password"
                  formClassName="passsword-form-group"
                  placeholder="Retype Password…"
                  id="retype-password"
                  onKeyDown={handleKeyDown}
                  isRequired
                  maxLength={20}
                  autoComplete="retype-password"
                  isInvalid={retypePassword.isInvalid}
                  isEmpty={retypePassword.isEmpty}
                  onChange={handleChangeRetypePassword}
                  value={retypePassword.value}
                />
              </div>
              <div className="d-flex flex-row justify-content-center align-items-center">
                <div>
                  <button
                    type="button"
                    className="btn btn-lg btn-primary btn-block btn-send-email"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>

            <div
              className={`success-submit fade ${
                isSendedResetPassword ? 'show visible' : 'hide invisible'
              }`}
            >
              <SvgCheckSuccess />
              <h1>Great, your password has already been set.</h1>
              <a
                href={Router.signin}
                className="btn btn-lg btn-darkprimary btn-reset-password"
              >
                Login
              </a>
            </div>
          </div>

          <footer className="footer py-3">
            <div className="container-fluid container-custom">
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <div className="align-items-center copyright">
                    {branding.NAME === 'Smart' && (
                      <span>
                        Copyright © 2024 <i>Smart!</i>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </>
      )}
      <div className="ornament"></div>
    </div>
  )
}

export default withRouter(ResetPassword)
