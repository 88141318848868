import React, { useEffect, useState, useRef } from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import * as Icon from 'react-feather'
import * as Router from '../../../shared/router'
import tippy from 'tippy.js'
import { getCurrentView } from '../../../shared/utility'

const MembershipResources = ({ user }) => {
  const [isDisabled, setIsDisabled] = useState(false)
  const containerRef = useRef()

  useEffect(() => {
    if (user.haveFreeTrial) {
      setIsDisabled(true)
      tippy(containerRef.current, {
        content: 'This feature is restricted during free trial.',
        animation: 'shift-away',
        zIndex: 1020,
        appendTo: containerRef.current,
      })
    }
  }, [user])

  const isPayg = user.membership.type === 'free'

  return (
    <div className="membership-button-div">
      {!isPayg && (
        <>
          <div className="w-100">
            <NavLink
              to={Router.managePackage}
              className={`membership-button d-flex ${
                isPayg ? 'flex-column' : 'flex-row'
              }`}
            >
              <Icon.File className="feather" />
              Membership
            </NavLink>
          </div>
          <div className="w-100" ref={containerRef}>
            {isDisabled ? (
              <NavLink
                to="#"
                className={`membership-button-disabled d-flex ${
                  isPayg ? 'flex-column' : 'flex-row'
                }`}
                disabled
              >
                <Icon.Plus className="feather" />
                Resources
              </NavLink>
            ) : (
              <NavLink
                to={Router.retail}
                className={`membership-button d-flex ${
                  isPayg ? 'flex-column' : 'flex-row'
                }`}
              >
                <Icon.Plus className="feather" />
                Resources
              </NavLink>
            )}
          </div>
        </>
      )}
      {isPayg && getCurrentView(user) !== 'client' && (
        <div className="w-100">
          <NavLink
            to={Router.payg}
            className="membership-button d-flex flex-column text-nowrap"
          >
            <Icon.CreditCard className="feather" />
            Manage Pay as you Go
          </NavLink>
        </div>
      )}
    </div>
  )
}

export default withRouter(MembershipResources)
