import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'

import './TmVerifyEmail.scss'
import * as Router from '../../../shared/router'
import branding from '../../../shared/branding'

import ImgSpinner from '../../../components/Loader/ImgSpinner/ImgSpinner'
import { useQuery } from '../../../shared/utility'
import { TmVerifyEmailAPI } from '../../../api/User/TmVerifyEmail'
import FormHandler from '../../../factory/FormHandler'
import toastCenter, { toastMessageType } from '../../../shared/toastCenter'
import LogoLogin from '../Logo.png'
import { RequestEmailVerificationAPI } from '../../../api/User/RequestEmailVerification'

function VerifyEmail({ history }) {
  let query = useQuery()
  let token = query.get('key')
  let userType = query.get('user') ? query.get('user') : 'customer'
  let email = query.get('email')

  const [pageLoading, setPageLoading] = useState(true)
  const [emailVerified, setEmailVerified] = useState(false)

  useEffect(() => {
    const tmVerifyEmailAPI = new TmVerifyEmailAPI()
    if (email && token && userType) {
      const data = new FormHandler()
      data.append('token', token)
      data.append('user', userType)
      data.append('email', email)
      const onNext = (response) => {
        if (response.success) {
          setEmailVerified(true)
          setPageLoading(false)
        } else {
          let message = response.messages
            ? response.messages
            : response.message
            ? response.message
            : 'Someting went wrong'
          toastCenter.message('Failed', message, toastMessageType.ERROR)
          if (response.data && response.data.action === 'token_expired') {
            setPageLoading(false)
          } else {
            setTimeout(() => {
              history.push(Router.signin)
            }, 2000)
          }
        }
      }
      const onComplete = () => {}
      const onError = () => {
        toastCenter.messageServerError()
      }
      tmVerifyEmailAPI.subscribe(data.all(), onNext, onComplete, onError)
    } else {
      history.push(Router.signin)
    }

    return () => {
      tmVerifyEmailAPI.unsubscribe()
    }
  }, [history, email, userType, token])

  const resendEmailVerification = () => {
    if (userType && email) {
      const requestEmailVerification = new RequestEmailVerificationAPI()
      const data = new FormHandler()
      data.append('token', token)
      data.append('userType', 'customer')
      data.append('email', email)

      const onNext = (response) => {
        if (response.success) {
          toastCenter.message(
            'Success',
            `An email has been sent to ${email} with a link to verify your account.`,
            toastMessageType.SUCCESS
          )
        } else {
          let message = response.messages
            ? response.messages
            : response.message
            ? response.message
            : 'Someting went wrong'
          toastCenter.message('Failed', message, toastMessageType.ERROR)
        }
      }
      const onComplete = () => {}
      const onError = () => {
        toastCenter.messageServerError()
      }
      requestEmailVerification.subscribe(
        data.all(),
        onNext,
        onComplete,
        onError
      )
    }
  }

  return (
    <div className="verify-email-wrapper h-100 overflow-hidden">
      <div className="ornament-top-1"></div>
      <div className="ornament-top-2"></div>
      <Helmet>
        <title>{branding.NAME} - Verify Email</title>
      </Helmet>

      {pageLoading ? (
        <ImgSpinner isLoading={true} />
      ) : (
        <>
          <div className="container position-relative h-100 zindex-1">
            <div className="logo">
              {branding.NAME === 'Smart' && (
                <a href={branding.DOMAIN}>
                  <img src={LogoLogin} width="150" alt="" />
                </a>
              )}
            </div>

            {emailVerified ? (
              <div className="email-verification-container fade show visible">
                <h1>Welcome to SMART!</h1>
                <p>
                  You have successfully verified your account. Now you can log
                  in to your dashboard and make your first order.
                </p>
                <a
                  href={Router.signin}
                  className="btn btn-lg btn-darkprimary rounded-pill btn-login"
                >
                  Log in to dashboard
                </a>
              </div>
            ) : (
              <div className="email-verification-container fade show visible">
                <h1>Failed!</h1>
                <p>
                  Email verification token is expired. Please retry verification
                  process by entering your registered email and clicking the
                  submit button below.
                </p>
                <button
                  className="btn btn-lg btn-darkprimary rounded-pill btn-login"
                  onClick={resendEmailVerification}
                >
                  Resend Email Verification
                </button>
              </div>
            )}
          </div>

          <footer className="footer py-3">
            <div className="container-fluid container-custom">
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <div className="align-items-center copyright">
                    {branding.NAME === 'Smart' && (
                      <span>
                        Copyright © 2024 <i>Smart!</i>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </>
      )}
      <div className="ornament"></div>
    </div>
  )
}

export default withRouter(VerifyEmail)
