import React from 'react'
import { useLocation } from 'react-router-dom'
import slugify from 'slugify'

import { production } from './constant'
import statusOrderOptions from './statusOrderOptions'
import progressOrderOptions from './progressOrderOptions'
import actionButtons from './actionButtons'
import defaultClientTheme from './defaultClientTheme'

import Logo from '../components/Logo/Logo'
import logoTypes from './logoTypes'
import SvgLogo from '../Icons/LogoIconApp'
import { userDetailService } from '../services/userDetailService/userDetailService'
import { destroyLocalSchedules } from '../shared/workingTimeKeeper'

export const hasQueryParams = (url) => {
  return url.includes('?')
}

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  }
}

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const truncateText = (n, len, offset = '...') => {
  if (n === null || n === undefined) {
    return 'Unknown'
  }
  let name = n
  if (name.length <= len) {
    return n
  }
  name = name.substr(0, len) + (n.length > len ? offset : '')
  return name
}

export const createMarkup = (html) => {
  return { __html: html }
}

export const output = console.log.bind(console)

export const printIf = (predicate) => {
  return (...args) => {
    if (predicate(...args)) {
      output(...args)
    }
  }
}

export const not = (fn) => {
  return (...args) => {
    return !fn(...args)
  }
}

export const isProd = () => {
  return production
}

export const isDev = not(isProd)

export const logging = printIf(isDev)

export const positionSuggestions = ({ state, props }) => {
  let transform
  let transition

  if (state.isActive && props.suggestions.length > 0) {
    transform = 'scaleY(1)'
    transition = 'all 0.25s cubic-bezier(.3,1.2,.2,1)'
  } else if (state.isActive) {
    transform = 'scaleY(0)'
    transition = 'all 0.25s cubic-bezier(.3,1,.2,1)'
  }

  return {
    transform,
    transition,
    bottom: '50px',
  }
}

export const emojiPositionTracker = (scroll = true) => {
  const sectionBody = document.getElementById('section-body-wrapper')
  const bodyHeight = Math.ceil(sectionBody.offsetHeight)
  const bodyTopPosition = Math.ceil(sectionBody.scrollTop)
  const bodyBottomPosition = bodyTopPosition + bodyHeight
  // comment starter
  const commentStarter = document.getElementById('comment-starter')

  let emojiTopPosition = 0
  let emojiBottomPosition = 0
  let emojiHeight = 0
  const emojiPickerWrapper = document.getElementById('emoji-picker-wrapper')
  if (emojiPickerWrapper) {
    emojiHeight = Math.ceil(emojiPickerWrapper.firstChild.offsetHeight)
    emojiTopPosition = Math.ceil(emojiPickerWrapper.offsetParent.offsetTop)
    emojiBottomPosition = emojiTopPosition + emojiHeight
    const emojiStyle = emojiPickerWrapper.style

    if (bodyTopPosition + 50 > emojiTopPosition) {
      emojiStyle.transform = `translateY(${
        bodyTopPosition - emojiTopPosition + 50
      }px)`
    } else {
      emojiStyle.transform = 'unset'
    }

    if (bodyBottomPosition - 100 < emojiBottomPosition) {
      emojiStyle.transform = `translateY(${
        bodyBottomPosition - 100 - emojiBottomPosition + 30
      }px)`
    }
  }

  // detect emoji position when showed up
  if (!scroll && emojiPickerWrapper && commentStarter) {
    const commentStarterTopPosition = Math.ceil(commentStarter.offsetTop)
    const emojiStyle = emojiPickerWrapper.style
    if (emojiBottomPosition > commentStarterTopPosition) {
      emojiStyle.transform = `translateY(${
        commentStarterTopPosition - emojiBottomPosition - 20
      }px)`
    }
  }
}

export const getOrderProgress = (progress) => {
  if (progress !== null && progress !== undefined) {
    let knownProgress = progress.id !== undefined ? progress.id : progress
    if (progress === 'draft' || progress === 'queue') {
      knownProgress = 'new-order'
    }
    let selectedProgress = progressOrderOptions.filter(
      (prg) => prg.name === knownProgress
    )
    if (selectedProgress.length === 1) return selectedProgress[0]
  }
  // set default to new-order
  return statusOrderOptions[0]
}

export const getActionButton = (key, action = () => {}, setLabel = null) => {
  const selectedBtn = actionButtons.filter((btn) => btn.name === key)
  if (selectedBtn.length > 0) {
    let { label, ...restStatus } = selectedBtn[0]
    label = setLabel ? setLabel : label
    return {
      label,
      action,
      ...restStatus,
    }
  }
}

export const validateAbn = (val, isRequired = false) => {
  const abn = val.replace(/[-_\s]/g, '')

  if (isRequired) {
    if (abn.length !== 11) {
      return false
    }
  } else {
    if (abn.length > 0 && abn.length !== 11) {
      return false
    }
  }

  return true
}

export const clearFormatAbn = (val) => {
  return val.replace(/[-_\s]/g, '')
}

export const formatAbn = (val) => {
  return val.replace(/[-_\s]/g, '')
}

export const pad = (number, digit = 2, initial = '0') =>
  number.toString().padStart(digit, initial)

export const checkPackageValue = (type) => {
  const packageValue = [
    {
      type: 'free',
      value: 0,
    },
    {
      type: 'beginner',
      value: 1,
    },
    {
      type: 'standard',
      value: 2,
    },
    {
      type: 'professional',
      value: 3,
    },
    {
      type: 'enterprise',
      value: 4,
    },
  ]

  const index = packageValue.findIndex((x) => x.type === type)
  if (index > -1) {
    return packageValue[index].type
  }

  return 0
}

export const isUpgradePackage = (currentType, type) => {
  return checkPackageValue(type) > checkPackageValue(currentType)
}

export const formatMoney = (
  amount,
  decimalCount = 2,
  decimal = '.',
  thousands = ','
) => {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString()
    let j = i.length > 3 ? i.length % 3 : 0

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    )
  } catch (e) {
    console.error(e)
  }
}

export const parseService = (serviceList = []) => {
  const services = []

  serviceList.forEach((s) => {
    if (s.services.length > 0) {
      services.push({
        category: s.category,
        services: s.services,
      })
    }

    if (s.childs.length > 0) {
      s.childs.forEach((x) => {
        if (x.services.length > 0) {
          services.push({
            category: x.category,
            services: x.services,
          })
        }

        if (x.childs.length > 0) {
          x.childs.forEach((y) => {
            if (y.services.length > 0) {
              services.push({
                category: y.category,
                services: y.services,
              })
            }
          })
        }
      })
    }
  })

  return services
}

export const isFloat = (n) => {
  return Number(n) === n && n % 1 !== 0
}

export const isInt = (n) => {
  return Number(n) === n && n % 1 === 0
}

export const capitalizeFirstLetter = (text) => {
  return text && text.charAt(0).toUpperCase() + text.substring(1)
}

export const objectToParams = (query, startOperator = '?') => {
  if (Object.keys(query).length > 0) {
    let params = []
    for (let key in query) {
      if (query.hasOwnProperty(key)) {
        params.push(key + '=' + encodeURIComponent(query[key]))
      }
    }

    return startOperator + params.join('&')
  }

  return ''
}

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export const parseHTML = (content) => {
  const parser = new DOMParser()
  return parser.parseFromString(`<!doctype html><body>${content}`, 'text/html')
    .body.textContent
}

export const toggleView = (user, view = null) => {
  let switchView =
    view || (getCurrentView(user) === 'client' ? 'customer' : 'client')
  userDetailService.emitReload(switchView)
  localStorage.setItem('view', switchView)
}

export const getCurrentView = (user) => {
  if (isClientOrCustomer(user)) {
    let currentView = localStorage.getItem('view')
    return currentView || 'customer'
  }
  return 'customer'
}

export const updateColorBased = (theme) => {
  if (typeof theme === 'object') {
    document.documentElement.style.setProperty(`--info`, theme.info)
    document.documentElement.style.setProperty(`--primary`, theme.primary)
    document.documentElement.style.setProperty(`--success`, theme.success)
    document.documentElement.style.setProperty(`--warning`, theme.warning)
    document.documentElement.style.setProperty(`--danger`, theme.danger)
  }
}

export const updateFavicon = (favicon) => {
  if (typeof favicon === 'string') {
    var link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = favicon
    document.getElementsByTagName('head')[0].appendChild(link)
  }
}

export const isClientOrCustomer = (user) => {
  if (user) {
    if (user.whitelabelInfo) {
      return true
    }
  }
  return false
}

export const isCustomerClient = (user) => {
  if (user) {
    if (
      user.whitelabelInfo !== null &&
      user.isClientWhitelabel === false &&
      user.isSubscriber === false
    ) {
      return true
    }
  }
  return false
}

export const handleLogo = (user, type = 'logo') => {
  if (isClientOrCustomer(user)) {
    let logoVar = type === logoTypes.LOGO ? logoTypes.LOGO : logoTypes.LOGO
    logoVar = type === logoTypes.FAVICON ? logoTypes.FAVICON : logoVar
    logoVar = type === logoTypes.PLACEHOLDER ? logoTypes.PLACEHOLDER : logoVar
    if (user.whitelabelInfo.logo) {
      return (
        <img
          src={user.whitelabelInfo[logoVar]}
          alt={user.name}
          style={{
            width: 'auto',
            height: 'auto',
            maxWidth: '100%',
            maxHeight: 65,
          }}
        />
      )
    } else {
      if (type === logoTypes.LOGO && user.ngineLogo) {
        return (
          <img
            src={user.ngineLogo}
            alt={user.name}
            style={{
              width: 'auto',
              height: 'auto',
              maxWidth: '100%',
              maxHeight: 65,
            }}
          />
        )
      } else if (type === logoTypes.FAVICON && user.ngineFavicon) {
        return (
          <img
            src={user.ngineFavicon}
            alt={user.name}
            style={{
              width: 'auto',
              height: 'auto',
              maxWidth: '100%',
              maxHeight: 65,
            }}
          />
        )
      }
    }
  } else if (user) {
    if (type === logoTypes.LOGO && user.ngineLogo) {
      return (
        <img
          src={user.ngineLogo}
          alt={user.name}
          style={{
            width: 'auto',
            height: 'auto',
            maxWidth: '100%',
            maxHeight: 65,
          }}
        />
      )
    } else if (type === logoTypes.FAVICON && user.ngineFavicon) {
      return (
        <img
          src={user.ngineFavicon}
          alt={user.name}
          style={{
            width: 'auto',
            height: 'auto',
            maxWidth: '100%',
            maxHeight: 65,
          }}
        />
      )
    }
  }
  let defaultLogo = <Logo width={150} />
  let defaultFavicon = <SvgLogo width={50} />
  return type === logoTypes.LOGO ? defaultLogo : defaultFavicon
}

export const resetKanban = () => {
  localStorage.removeItem('clientId')
  localStorage.removeItem('token')
  localStorage.removeItem('expirationDate')
  localStorage.removeItem('userId')
  localStorage.removeItem('theme')
  localStorage.removeItem('view')
  localStorage.removeItem('subscription-leave-modal')
  updateColorBased(defaultClientTheme)
  updateFavicon(`${process.env.PUBLIC_URL}/favicon-32x32.png`)
  destroyLocalSchedules()
}

export const slugUrl = (text, replacement = '_') => {
  return slugify(text, {
    replacement: replacement,
    remove: /[!@#$%^&*(),.?":{}|<>]/,
    lower: false,
    strict: true,
  })
}

export const generatePassword = () => {
  const randomPassword = Array(14)
    .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$')
    .map(
      (x) =>
        x[
          Math.floor(
            (crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1)) *
              x.length
          )
        ]
    )

  const lowerCase = Array(2)
    .fill('abcdefghijklmnopqrstuvwxyz')
    .map(
      (x) =>
        x[
          Math.floor(
            (crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1)) *
              x.length
          )
        ]
    )

  const capitalCase = Array(2)
    .fill('ABCDEFGHIJKLMNOPQRSTUVWXYZ')
    .map(
      (x) =>
        x[
          Math.floor(
            (crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1)) *
              x.length
          )
        ]
    )

  const numberCase = Array(2)
    .fill('0123456789')
    .map(
      (x) =>
        x[
          Math.floor(
            (crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1)) *
              x.length
          )
        ]
    )

  const randomPasswordArray = [
    ...randomPassword,
    ...lowerCase,
    ...capitalCase,
    ...numberCase,
  ]

  shuffle(randomPasswordArray)

  return randomPasswordArray.join('')
}

export const shuffle = (array) => {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

export const getBrowserDetail = () => {
  let browser = {}
  if (/Edg\/[0-9]{2}/i.test(navigator.userAgent)) {
    browser.agent = 'edge'
    browser.majorVersion = parseInt(
      /Edg\/([0-9]{2})/i.exec(navigator.userAgent)[1]
    )
    browser.version = /Edg\/([0-9.]+)/i.exec(navigator.userAgent)[1]
  } else if (/Edge\/[0-9]{2}/i.test(navigator.userAgent)) {
    browser.agent = false
    browser.majorVersion = false
    browser.version = false
  } else if (/OPR\/[0-9]{2}/i.test(navigator.userAgent)) {
    browser.agent = 'opera'
    browser.majorVersion = parseInt(
      /OPR\/([0-9]{2})/i.exec(navigator.userAgent)[1]
    )
    browser.version = /OPR\/([0-9.]+)/i.exec(navigator.userAgent)[1]
  } else if (/chrome\/[0-9]{2}/i.test(navigator.userAgent)) {
    browser.agent = 'chrome'
    browser.majorVersion = parseInt(
      /chrome\/([0-9]{2})/i.exec(navigator.userAgent)[1]
    )
    browser.version = /chrome\/([0-9.]+)/i.exec(navigator.userAgent)[1]
  } else if (/firefox\/[0-9]{2}/i.test(navigator.userAgent)) {
    browser.agent = 'firefox'
    browser.majorVersion = parseInt(
      /firefox\/([0-9]{2})/i.exec(navigator.userAgent)[1]
    )
    browser.version = /firefox\/([0-9.]+)/i.exec(navigator.userAgent)[1]
  } else if (/FxiOS\/[0-9.]+/i.test(navigator.userAgent)) {
    browser.agent = 'firefox mobile'
    browser.majorVersion = parseInt(
      /FxiOS\/([0-9]{2})/i.exec(navigator.userAgent)[1]
    )
    browser.version = /FxiOS\/([0-9.]+)/i.exec(navigator.userAgent)[1]
  } else if (/CriOS\/[0-9.]+/i.test(navigator.userAgent)) {
    browser.agent = 'chrome mobile'
    browser.majorVersion = parseInt(
      /CriOS\/([0-9]{2})/i.exec(navigator.userAgent)[1]
    )
    browser.version = /CriOS\/([0-9.]+)/i.exec(navigator.userAgent)[1]
  } else if (/Safari\/[0-9.]+/i.test(navigator.userAgent)) {
    browser.agent = 'safari'
    browser.majorVersion = parseInt(
      /Version\/([0-9]{2})/i.exec(navigator.userAgent)[1]
    )
    browser.version = /Version\/([0-9.]+)/i.exec(navigator.userAgent)[1]
  } else {
    // force ie and others to false
    browser.agent = false
    browser.majorVersion = false
    browser.version = false
  }

  if (/Windows\sNT/.test(navigator.userAgent)) {
    browser.os = 'windows'
    browser.osversion = false
  } else if (/OS\sX\s/.test(navigator.userAgent)) {
    browser.os = 'os x'
    browser.osversion = false
  } else if (/(Linux)/.test(navigator.userAgent)) {
    browser.os = 'linux'
    browser.osversion = false
  } else if (/(iPhone)/.test(navigator.userAgent)) {
    browser.os = 'iPhone'
    browser.osversion = false
  } else if (/(iPad)/.test(navigator.userAgent)) {
    browser.os = 'iPad'
    browser.osversion = false
  }

  return browser
}
