import React from 'react'
import PropTypes from 'prop-types'

import './LoaderComponent.scss'

import Spinner from '../Spinner/Spinner'

function LoaderComponent({ branding = null, loading = false }) {
  return (
    <div className="loader-component-loader">
      <Spinner />
    </div>
  )
}

LoaderComponent.propType = {
  branding: PropTypes.object,
  loading: PropTypes.bool,
}

export default LoaderComponent
