import React from "react";

const SvgCheckSuccess = props => (
  <svg width={87} height={78} {...props}>
    <g fill="#FFF" fillRule="evenodd">
      <path
        d="M38.492 54.925L22.255 38.688l4.95-4.949 11.287 11.288 30.044-30.044C61.483 6.163 50.654.5 38.485.5 17.229.5-.001 17.73-.001 38.982c0 21.256 17.23 38.483 38.486 38.483 21.252 0 38.482-17.227 38.482-38.483a38.335 38.335 0 00-4.497-18.035L38.492 54.925z"
        fillOpacity={0.5}
      />
      <path
        d="M86.468 6.95L81.518 2 68.535 14.983a38.226 38.226 0 013.934 5.968l14-14z"
        fillOpacity={0.7}
      />
      <path d="M27.204 33.74l-4.95 4.949 16.237 16.236L72.47 20.947a38.36 38.36 0 00-3.934-5.964L38.491 45.027 27.204 33.74z" />
    </g>
  </svg>
);

export default SvgCheckSuccess;
