import * as actionTypes from './actionTypes'

export const flash = (content) => {
  return {
    type: actionTypes.FLASH_NOTIFICATION_SHOW,
    content,
  }
}

export const flashHide = () => {
  return {
    type: actionTypes.FLASH_NOTIFICATION_HIDE,
  }
}

export const flashStatusType = {
  PRIMARY: 'primary',
  WARNING: 'warning',
  DANGER: 'danger',
}

export const userScheduleNotificationContent = (
  action = 'downgrade',
  key = 'cancel-downgrade'
) => ({
  type: actionTypes.FLASH_NOTIFICATION_SHOW,
  content: `<p>You are scheduled for a ${action} on your next subscription cycle, Click <a href='#' data-confirmation-box='${key}'>here</a> if you want to cancel the ${action}.</p>`,
})

export const userEmailVerificationNotificationContent = () => ({
  type: actionTypes.FLASH_NOTIFICATION_SHOW,
  status: flashStatusType.WARNING,
  content:
    "<p>We just sent you an email with verification link. Please verify your email address before continuing using our services. <a href='#' data-confirmation-box='resend-email-verification'>Click here</a> to resend the email verification.</p>",
})

export const userRequestDeletionNotificationContent = (
  endSubscriptionCycleOn
) => ({
  type: actionTypes.FLASH_NOTIFICATION_SHOW,
  status: flashStatusType.WARNING,
  content: `<p>You are requesting for account deletion. Your account will be deleted on ${endSubscriptionCycleOn}`,
})
